import React from 'react';
import './assets/styles/App.css';
import '../node_modules/nes.css/css/nes.min.css'
import Home from './pages/Home.js';

function App() {
  return (
    <div id="App">
      <main>
        <Home/>
      </main>
    </div>
  );
}

export default App;
