import React from 'react';
import '../assets/styles/pages/Home.css';

function App() {
  return (
    <div id="homePage">
      <section id="landingScreen">
        <h1 id="landingScreenHeader">tilted.io</h1>
      </section>
    </div>
  );
}

export default App;